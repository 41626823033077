/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { ThemeProvider } from "styled-components"
import { GridThemeProvider } from "styled-bootstrap-grid"
import NormalizedStyled from "./src/styles/normalize"
import { BaseCSS } from "styled-bootstrap-grid"
import theme, { grid as gridTheme } from "./src/styles/theme"
import "swiper/css/swiper.css"
import "./src/styles/initialStyles.css"

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <NormalizedStyled />
      <BaseCSS />
      <GridThemeProvider gridTheme={gridTheme}>{element}</GridThemeProvider>
    </ThemeProvider>
  )
}
