const colors = {
  lightGray: "#F9F9F9 ",
  gray: "#ECECEC",
  gray100: "#E1DEDE",
  black: "#282325",
  white: "#ffffff",
  orange: "#ee8522",
  red: "#e74c3c",
  yellow: "#FED75C",
}

export default colors
