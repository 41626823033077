// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bramy-i-furtki-js": () => import("./../../../src/pages/bramy-i-furtki.js" /* webpackChunkName: "component---src-pages-bramy-i-furtki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ogrodzenia-betonowe-js": () => import("./../../../src/pages/ogrodzenia-betonowe.js" /* webpackChunkName: "component---src-pages-ogrodzenia-betonowe-js" */),
  "component---src-pages-ogrodzenia-panelowe-js": () => import("./../../../src/pages/ogrodzenia-panelowe.js" /* webpackChunkName: "component---src-pages-ogrodzenia-panelowe-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */)
}

