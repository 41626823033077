import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

const normalizedStyles = createGlobalStyle`

* {
    outline-color: ${({ theme }) => theme.colors.primary}
}

a {
    text-decoration: none;
}

${normalize()}

`

export default normalizedStyles
