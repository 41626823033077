import colors from "./colors"
import breakpoints from "./breakpoints"

const theme = {
  colors: {
    primary: colors.orange,
    secondary: colors.red,
    text: colors.black,
    dark: colors.black,
    light: colors.lightGray,

    ...colors,
  },

  spacing: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "1rem",
    4: "1.5rem",
    5: "2rem",
    6: "3rem",
    7: "4rem",
  },

  breakpoints,

  sizes: {
    navHeight: "80px",
    navHeightMobile: "50px",
  },
}

export default theme

export const grid = {
  breakpoints: { ...breakpoints, xs: breakpoints.sm - 1 },
}

